import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { ToastContentProps } from 'react-toastify';

import Cross from '@icons/ui-kit/Cross.svg';
import SnackBarMark from '@icons/ui-kit/snackBarMark.svg';
import SnackBarMarkNoShadow from '@icons/ui-kit/snackBarMarkNoShadow.svg';
import { useCurrentTheme, THEME } from '@src/api/local/theme';
import Button from '@ui/button/Button';

import s from './add-item-message.module.sass';

const AddItemMessage: React.FC<Omit<ToastContentProps, 'toastProps'>> = ({ closeToast }) => {
  const { t } = useTranslation('stock');

  const colorTheme = useCurrentTheme();
  const isLightTheme = colorTheme.data?.theme === THEME.light;

  return (
    <div className={s.notificationInner}>
      <div className={s.notificationText}>
        {isLightTheme ? <SnackBarMark /> : <SnackBarMarkNoShadow />}
        <p>{t('Stock.AddItem.itemAdded')}</p>
        <div className={cx(s.notificationClose, s.notificationCloseMobile)}>
          <Button theme="ternary superSmall" onClick={closeToast}>
            <Cross />
          </Button>
        </div>
      </div>
      <div className={s.notificationButtons}>
        <div className={s.buttonStock}>
          <Button theme="middle" link="/stock">
            {t('Stock.AddItem.toStock')}
          </Button>
        </div>
        <div className={s.notificationClose}>
          <Button theme="ternary superSmall" onClick={closeToast}>
            <Cross />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddItemMessage;
