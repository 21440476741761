import { GetStaticProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';

import CartCard from '@components/marketplace/CartCard/CartCard';
import { Media } from '@src/utils/media';
import styles from '@styles/marketplace.module.sass';
import MainLayout from 'src/components/layout/MainLayout';
import Filters from 'src/components/marketplace/Filters/Filters';
import FiltersMobile from 'src/components/marketplace/FiltersMobile/FiltersMobile';
import MarketplaceItems from 'src/components/marketplace/MarketplaceItems';
import '@components/add-item/AddItemMessage/AddItemMessage';
import '@ui/ToastMessage';
import 'src/components/basket/notify/notify';

const Marketplace = () => {
  const { t } = useTranslation(['marketplace', 'common']);
  return (
    <MainLayout
      title={t('marketplace:Marketplace.marketplaceTitle')}
      contentTitle={t('marketplace:Marketplace.marketplaceContentTitle')}
      description={t('marketplace:Marketplace.marketplaceDescription')}
    >
      <div className={styles.cartCardWrapper}>
        <CartCard />
      </div>

      <Media greaterThanOrEqual="lPhone">
        <Filters />
      </Media>

      <Media lessThan="lPhone">
        <FiltersMobile />
      </Media>

      <MarketplaceItems />
    </MainLayout>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale as string, ['marketplace', 'common', 'stock'])),
  },
});

export default Marketplace;
